<template>
  <div
    class="userguanli"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="entryName"
          label="项目名称"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="company"
          label="企业名称"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="企业地址"
          width="120"
        ></el-table-column>
        <el-table-column prop="socialCreditCode" label="信用代码" width="130">
        </el-table-column>
        <el-table-column prop="userName" label="联系人名称" width="130">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="120">
        </el-table-column>
        <el-table-column prop="groupName" label="销售分组" width="100">
        </el-table-column>
        <el-table-column prop="counterpart" label="业务对接人" width="120">
        </el-table-column>
        <el-table-column
          prop="serviceStartTime"
          label="服务开始时间"
          width="160"
        >
        </el-table-column>
        <el-table-column prop="serviceEndTime" label="服务结束时间" width="160">
        </el-table-column>
        <el-table-column prop="disableLogin" label="是否禁止登录" width="120">
        </el-table-column>
        <el-table-column prop="alarmNotice" label="是否启用APP" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.alarmNotice == 0">否</span>
            <span v-else>是</span>
          </template>
        </el-table-column>
        <el-table-column prop="caozuo" label="操作" width="230">
          <template slot-scope="scope">
            <el-button
              class="details"
              size="mini"
              @click="disableLogin(scope.$index, scope.row)"
              >禁止登录</el-button
            >
            <el-button
              class="details"
              size="mini"
              @click="changeUser(scope.$index, scope.row)"
              >编辑</el-button
            >
            <!-- <el-button
              class="details"
              size="mini"
              @click="details(scope.$index, scope.row)"
              >禁用账号</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="totals">
      <!-- 左 -->
      <div class="left">
        <p>
          总用户数
          <span class="spanto">{{ totalCount }}</span
          >人
        </p>
      </div>
      <!-- 右 -->
      <div class="total">
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :current-page="pageCount"
            layout="total, prev, pager, next"
            :total="totalCount"
          >
          </el-pagination>
        </div>
        <!-- 按钮 -->
        <div class="buttons">
          <el-button type="text" @click="add">添加</el-button>
        </div>
      </div>
    </div>

    <!-- 增加弹框-->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="clearUserForm"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label="项目名称" prop="entryName">
            <el-input
              v-model="ruleForm.entryName"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业名称" prop="company">
            <el-input
              v-model="ruleForm.company"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="销售分组" prop="groupId">
            <el-select
              v-model="ruleForm.groupId"
              placeholder="请选择"
              @change="$forceUpdate()"
            >
              <el-option
                v-for="item in optionsCus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否禁止登录" prop="disableLogin">
            <el-select
              v-model="ruleForm.disableLogin"
              placeholder="请选择"
              @change="$forceUpdate()"
            >
              <el-option
                v-for="item in optionsLo"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用APP" prop="alarmNotice">
            <el-select
              v-model="ruleForm.alarmNotice"
              placeholder="请选择"
              @change="$forceUpdate()"
            >
              <el-option
                v-for="item in AppOptions"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="API推送类型" prop="pushType">
            <el-select
              v-model="ruleForm.pushType"
              placeholder="请选择"
              @change="$forceUpdate()"
            >
              <el-option
                v-for="item in optionsPush"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业地址" prop="address">
            <el-input
              v-model="ruleForm.address"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="信用代码" prop="socialCreditCode">
            <el-input
              v-model="ruleForm.socialCreditCode"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人名称" prop="userName">
            <el-input
              v-model="ruleForm.userName"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="ruleForm.phone" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="业务对接人" prop="counterpart">
            <el-input
              v-model="ruleForm.counterpart"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="API_ID" prop="apiId">
            <el-input v-model="ruleForm.apiId" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="API键值" prop="apiKey">
            <el-input v-model="ruleForm.apiKey" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="API秘钥" prop="apiSecret">
            <el-input
              v-model="ruleForm.apiSecret"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户账号" prop="loginName">
            <el-input
              v-model="ruleForm.loginName"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户密码" prop="loginPass">
            <el-input
              v-model="ruleForm.loginPass"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="API订阅推送接口" prop="pushUrl">
            <el-input
              v-model="ruleForm.pushUrl"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务结束时间" prop="">
            <!-- <el-date-picker
              v-model="serValidity.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker> -->
            <el-date-picker
              v-model="ruleForm.serviceEndTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearUserForm">取 消</el-button>
          <el-button type="primary" @click="addUserList">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 修改 -->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisibleChange"
        width="50%"
        :before-close="clearUserFormChange"
      >
        <el-form
          :model="ruleForm1"
          :rules="rules"
          ref="ruleForm1"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label="项目名称" prop="entryName">
            <el-input
              v-model="ruleForm1.entryName"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业名称" prop="company">
            <el-input
              v-model="ruleForm1.company"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="销售分组" prop="groupId">
            <el-select
              v-model="ruleForm1.groupId"
              placeholder="请选择"
              @change="$forceUpdate()"
            >
              <el-option
                v-for="item in optionsCus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否禁止登录" prop="disableLogin">
            <el-select
              v-model="ruleForm1.disableLogin"
              placeholder="请选择"
              @change="$forceUpdate()"
            >
              <el-option
                v-for="item in optionsLo"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用APP" prop="alarmNotice">
            <el-select
              v-model="ruleForm1.alarmNotice"
              placeholder="请选择"
              @change="$forceUpdate()"
            >
              <el-option
                v-for="item in AppOptions"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="API推送类型" prop="pushType">
            <el-select
              v-model="ruleForm1.pushType"
              placeholder="请选择"
              @change="$forceUpdate()"
            >
              <el-option
                v-for="item in optionsPush"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业地址" prop="address">
            <el-input
              v-model="ruleForm1.address"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="信用代码" prop="socialCreditCode">
            <el-input
              v-model="ruleForm1.socialCreditCode"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人名称" prop="userName">
            <el-input
              v-model="ruleForm1.userName"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="ruleForm1.phone" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="业务对接人" prop="counterpart">
            <el-input
              v-model="ruleForm1.counterpart"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="API_ID" prop="apiId">
            <el-input v-model="ruleForm1.apiId" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="API键值" prop="apiKey">
            <el-input
              v-model="ruleForm1.apiKey"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="API秘钥" prop="apiSecret">
            <el-input
              v-model="ruleForm1.apiSecret"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户账号" prop="loginName">
            <el-input
              v-model="ruleForm1.loginName"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户密码" prop="loginPass">
            <el-input
              v-model="ruleForm1.loginPass"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="API订阅推送接口" prop="pushUrl">
            <el-input
              v-model="ruleForm1.pushUrl"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务结束时间" prop="">
            <!-- <el-date-picker
              v-model="serValidity.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker> -->
            <el-date-picker
              v-model="ruleForm1.serviceEndTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearUserFormChange">取 消</el-button>
          <el-button type="primary" @click="changeUserLs">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <el-dialog title="提示" :visible.sync="dialogin" width="30%">
        <span>禁止登录：</span>
        <el-select v-model="valDisLog" placeholder="请选择">
          <el-option
            v-for="item in optionsLo"
            :key="item.code"
            :label="item.name"
            :value="item.code"
            clearable
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogin = false">取 消</el-button>
          <el-button type="primary" @click="quitDisLog">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      serValidity: {
        time: [],
      },
      twoID: "",
      // 弹框
      dialogVisible: false,
      dialogVisibleChange: false,
      dialogVisibleTwo: false,
      dialogin: false,
      valDisLog: "",
      title: "添加用户",
      ruleForm: {},
      ruleForm1: {},
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      optionsCus: [],
      value: "",
      //   table
      tableData: [],
      rules: {
        // entryName: [
        //   { required: true, message: "请输入ID名称", trigger: "blur" },
        // ],
        // company: [{ required: true, message: "请输入ID名称", trigger: "blur" }],
        // socialCreditCode: [
        //   { required: true, message: "请输入ID名称", trigger: "blur" },
        // ],
        phone: [{ required: true, message: "请输入ID名称", trigger: "blur" }],
      },
      optionsLo: [],
      AppOptions: [],
      optionsPush: [],
      xiuId: null,
      changeID: null,
    };
  },
  // components: {
  //   rolemanage,
  //   addaccount
  // },
  created() {
    this.menulist();
    apiShout.getGroups().then((res) => {
      if (res.data.code === 200) {
        this.optionsCus = res.data.result;
      }
    });
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    // xuan(val){
    //   console.log('5656:',val);
    // },
    disableLogin(index, row) {
      this.xiuId = row.id;
      this.dialogin = true;
      this.yes();
    },
    quitDisLog() {
      apiShout
        .upAla({ id: this.xiuId, disableLogin: this.valDisLog })
        .then((res) => {
          console.log("禁等：", res.data);
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "禁止登录成功!",
            });
            this.dialogin = false;
            this.menulist();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //用户列表
    menulist() {
      apiShout
        .getalaList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          // startDate:
          //   this.overallForm.time == null ? "" : this.overallForm.time[0],
          // endDate:
          //   this.overallForm.time == null ? "" : this.overallForm.time[1],
          // status: this.SerachInput.status,
          // type: this.SerachInput.userInfo,
          // value: this.SerachInput.valueInfo,
          // centerType: this.SerachInput.centerType,
          // sysCreate: this.SerachInput.sysCreate,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.tableData.forEach((ele) => {
              if (ele.disableLogin == 0) {
                ele.disableLogin = "否";
              } else {
                ele.disableLogin = "是";
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //添加用户
    add() {
      this.dialogVisible = true;
      this.yes();
      this.pushList();
      this.ruleForm.disableLogin = "0";
      this.ruleForm.alarmNotice = "0";
    },
    //添加用户
    addUserList() {
      if (
        !/^1[0123456789]\d{9}$/.test(this.ruleForm.phone) ||
        this.ruleForm.phone == ""
      ) {
        this.$message.error("手机号码不正确，请重新添加或不能为空");
        return false;
      }
      apiShout
        .getaddAla({
          address: this.ruleForm.address,
          apiId: this.ruleForm.apiId,
          apiKey: this.ruleForm.apiKey,
          apiSecret: this.ruleForm.apiSecret,
          company: this.ruleForm.company,
          counterpart: this.ruleForm.counterpart,
          disableLogin: this.ruleForm.disableLogin,
          entryName: this.ruleForm.entryName,
          groupId: this.ruleForm.groupId,
          loginName: this.ruleForm.loginName,
          loginPass: this.ruleForm.loginPass,
          phone: this.ruleForm.phone,
          pushType: this.ruleForm.pushType,
          pushUrl: this.ruleForm.pushUrl,
          serviceEndTime: this.ruleForm.serviceEndTime,
          socialCreditCode: this.ruleForm.socialCreditCode,
          userName: this.ruleForm.userName,
          alarmNotice: this.ruleForm.alarmNotice,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.dialogVisible = false;
            this.menulist();
            this.ruleForm = {};
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //编辑用户
    changeUser(index, row) {
      console.log(index, row);
      this.dialogVisibleChange = true;
      this.changeID = row.id;
      this.pushList();
      this.ruleForm1 = row;
      this.yes();
    },
    //编辑的确定
    changeUserLs() {
      if (
        !/^1[0123456789]\d{9}$/.test(this.ruleForm1.phone) ||
        this.ruleForm1.phone == ""
      ) {
        this.$message.error("手机号码不正确，请重新添加或不能为空");
        return false;
      }
      if (this.ruleForm1.disableLogin == "是") {
        this.ruleForm1.disableLogin = "1";
      } else {
        this.ruleForm1.disableLogin = "0";
      }
      apiShout
        .upAla({
          id: this.changeID,
          address: this.ruleForm1.address,
          apiId: this.ruleForm1.apiId,
          apiKey: this.ruleForm1.apiKey,
          apiSecret: this.ruleForm1.apiSecret,
          company: this.ruleForm1.company,
          counterpart: this.ruleForm1.counterpart,
          disableLogin: this.ruleForm1.disableLogin,
          entryName: this.ruleForm1.entryName,
          groupId: this.ruleForm1.groupId,
          loginName: this.ruleForm1.loginName,
          loginPass: this.ruleForm1.loginPass,
          phone: this.ruleForm1.phone,
          pushType: this.ruleForm1.pushType,
          pushUrl: this.ruleForm1.pushUrl,
          serviceEndTime: this.ruleForm1.serviceEndTime,
          socialCreditCode: this.ruleForm1.socialCreditCode,
          userName: this.ruleForm1.userName,
          alarmNotice: this.ruleForm1.alarmNotice,
        })
        .then((res) => {
          console.log("修改列表：", res.data);
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.dialogVisibleChange = false;
            this.menulist();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //删除用户
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisibleTwo = true;
      this.twoID = row.id;
    },
    deleteTwo() {
      this.dialogVisibleTwo = false;
    },
    //用户详情
    details(index, row) {
      console.log(index, row);
    },
    //关闭详情按钮
    detailsX() {
      //清空表单数据
      this.clearUserForm();
    },

    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.menulist();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.menulist();
    },
    clearUserFormChange() {
      this.dialogVisibleChange = false;
      this.menulist();
    },
    clearUserForm() {
      this.dialogVisible = false;
    },
    yes() {
      api.getSelectProduct({ dictCode: "isno" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.optionsLo = res.data.result;
          this.AppOptions = res.data.result;
        }
      });
    },
    pushList() {
      api.getSelectProduct({ dictCode: "apipushtype" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.optionsPush = res.data.result;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.userguanli {
  // 搜索
  .sousuo {
    // background: red;
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .div1 {
      margin-right: 20px;
      .inputs {
        width: 231px;
        height: 33px;
        border: 1px solid #e5e5e5;
        margin-left: 5px;
      }
      .inputs:focus {
        outline: 1px solid #e5e5e5; //边框不用border，用outline
      }
      span {
        font-size: 14px;
        color: #909399;
        font-weight: 700;
      }
      .select {
        margin-left: 20px;
      }
    }
    .imgs {
      width: 49px;
    }
  }
  //   table
  .tables {
    position: relative;
    margin-left: 25px;
    margin-right: 40px;

    .tingyong {
      color: rgb(97, 97, 245);
    }
    .buts {
      width: 36px;
      height: 27px;
      margin: 0 10px;
    }
    .el-table {
      // max-height: 500px;
      // overflow: hidden;
      tr {
        th {
          font-size: 14px;
          border: none;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #909399;
        }
        td {
          border: none;
          text-align: center;
          font-size: 14px;
          color: #606266;
        }
      }
      .details {
        height: 27px;
        border-radius: 15px;
      }
    }
    .el-table::before {
      height: 0;
    }
    // .details {
    //   height: 30px;
    //   position: absolute;
    //   top: 12px;
    //   right: 1px;
    //   border-radius: 15px;
    // }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  .totals {
    display: flex;
    justify-content: space-between;
    .left {
      p {
        font-size: 16px;
        display: inline-block;
        margin-right: 80px;

        margin-left: 50px;

        margin-top: 20px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      margin-right: 50px;
      // 按钮
      .buttons {
        display: flex;
        .el-button {
          width: 130px;
          height: 40px;
          line-height: 40px;
          border-radius: 25px;
          margin-top: 20px;
          margin-right: 20px;
          background: #d78673;
          color: #ffffff;
          font-size: 16px;
          padding: 0px;
        }
      }
      // page
      .pages {
        display: flex;
        align-items: center;
        .el-pagination {
          padding: 0;
          img {
            display: inline-block;
          }
          .lefts {
            margin-right: 25px;
          }
          .rights {
            float: right;
            margin-left: 25px;
          }
        }
      }
    }
  }
  // .el-input__inner {
  //   width: 50%;
  // }
  .lizhi {
    margin-left: -10px;
  }
  .account .el-input__inner {
    border: 1px solid #fff;
  }
  .el-form-item__label {
    margin-left: 60px;
  }
  .workState {
    margin: 70px 120px;
    span {
      margin-right: 15px;
    }
  }
  .sexState {
    position: absolute;
    right: 173px;
    top: 245px;
    span {
      margin-right: 15px;
    }
  }
  .jigouState {
    position: absolute;
    right: 173px;
    top: 180px;
    span {
      margin-right: 15px;
    }
  }
  .jueseState {
    position: absolute;
    left: 133px;
    top: 245px;
    span {
      margin-right: 15px;
    }
  }
  .dengState {
    position: absolute;
    left: 105px;
    top: 180px;
    span {
      margin-right: 15px;
    }
  }
  .workState1 {
    margin: 130px 120px;
    span {
      margin-right: 15px;
    }
  }
  // 弹框
  /deep/.el-dialog {
    border-radius: 20px;
  }
  /deep/.el-dialog__title {
    line-height: 24px;
    font-size: 18px;
    color: #d78673;
  }
  // .el-dialog__header {
  //   background: #eaeaea;
  //   border-radius: 18px 18px 0 0;
  // }
  // .el-dialog__close {
  //   color: transparent;
  //   background-image: url("../../assets/images/close.png");
  //   background-repeat: no-repeat;
  //   background-size: 14px;
  // }
  // .el-dialog__body {
  //   background: #eaeaea;
  //   border-radius: 0 0 18px 18px;
  //   padding: 10px 0px 0 0px;
  // }
  .accountdd {
    .el-dialog__header {
      background: #ffffff;
      border-radius: 18px 18px 0 0;
    }
    .el-dialog__body {
      background: #ffffff;
      border-radius: 0 0 18px 18px;
      padding: 10px 0px 0 0px;
    }
  }
  /deep/.el-input__inner {
    border-radius: 0;
    height: 33px !important;
    line-height: 33px !important;
  }
  /deep/.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 33px !important;
  }
  /deep/.el-form-item__label {
    color: #909399;
    text-align: center;
  }
  /deep/.el-dialog__body {
    padding: 0;
    margin-left: 2%;
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
</style>